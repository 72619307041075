<template lang="en">
  <main id="main" >
   <page-heading v-show="isHomePage === false" :heading="heading" />
    <background-elements v-show="isHomePage === false" :className="orangeCircleMain" />
    <background-elements v-show="isHomePage === true" :className="orangeCircleHome" />
    <background-elements v-show="isHomePage === true" :className="greenDotsHome" />
    <background-elements v-show="isHomePage === false" :className="greenDots" />
    <background-elements v-show="isHomePage === true"  :className="orangeDotsHome" />
    <background-elements v-show="isHomePage === true"  :className="circleHome" />
    <background-elements v-show="isHomePage === true"  :className="whiteDotsHome" />
   <slot></slot>
  </main>
</template>

<script>
import PageHeading from "../PageHeading/_PageHeading.vue";
import BackgroundElements from "../BackgroundElements/BackgroundElements.vue";


export default { 
  name:"Main",
  data() {
    return {
      orangeCircleMain:"orangeCircle main",
      greenDots:"greenDots main",
      orangeDotsHome:"orangeDots home",
      circleHome:"circleHome",
      whiteDotsHome:"whiteDotsHome",
      greenDotsHome:"greenDotsHome",
      orangeCircleHome:"orangeCircleHome",
    }
  },
  components:{
    PageHeading,
    BackgroundElements
  },
  props:{
    heading: {
      type: String,
    },
  isHomePage: {
      type: Boolean,
      default:false,
    }
  },
  mounted() {
     let fontSize = localStorage.getItem("fontSize");
    if(fontSize) {
       document.querySelector("#main").style.fontSize=fontSize;
    } 
  },
}
</script>

<style lang="scss">
</style>
<template lang="en">
  <div id="mainView" class="main__view container" :class="{ contactPage:isContactPage }">
    <sub-navigation v-if="isContactPage === false" :categories="categories"/>
    <slot></slot>
  </div>
</template>

<script>
import SubNavigation from "../SubNavigation/_SubNavigation.vue";
export default {
  name:'MainView',
  components:{
    SubNavigation,
  },
  props:{
    isContactPage: {
      type: Boolean,
      default:false,
    },
    categories:{
      type: Array,
    }
  }
}
</script>

<style lang="scss">
  @import "./mainview.scss";
</style>
<template lang="en">
  <nav class='sub__nav'>
    <ul>
      <li :class="(navClass === 'subNav' && (currentSection === path.split('=').pop() || fullPath === path || fullPath === path + '#mainView')) ? 'activeArrow' : (currentSection === path.split('=').pop() || fullPath === path || fullPath === path + '#mainView') ? 'active' : navClass === 'subNav' ? 'linkArrow' : 'link'"  @click.native="scrollToTop(index)" v-for="({path, name, navClass}, index) in categories" :key="index">
      <router-link ref="links"  :to="path"><span v-if="navClass === 'subNav'" class=arrow></span> {{name}}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>

export default {
  name: "SubNavigation",
  data() {
    return {
      activeItem:null,
      currentSection:null,
      fullPath:null,
    }
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
  methods: {
    scrollToTop(index){
        let currentScroll = document.documentElement.scrollTop,
          int = setInterval(frame, 6)
          this.activeItem = index;
          function frame(){
              if( 0 > currentScroll)
                  clearInterval(int)
                  
              else {
                  currentScroll = currentScroll - 12
                  document.documentElement.scrollTop = currentScroll
              }
          }
      },
  },
    watch: {
     $route(to){
       this.currentSection = to.query.section;
        this.fullPath = to.fullPath;
     }
    }
};
</script>


<style lang="scss" scoped>
@import "./subNavigation.scss";
</style>

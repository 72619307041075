<template lang="en">
    <h2>History</h2>
    <p>
      ICRT was created as a not-for-profit association under UK law in 1990, but collaboration on product testing between independent consumer organisations began more than 50 years ago. 
    </p>
    <ol class="pages-ol">
      <li><span>1968</span> <p>The first joint tests between the Dutch and Belgian organisations included hairdryers, dishwashers, stereo receivers and model trains. </p> </li>
      <li><span>1972</span> <p>The name European Testing Group (ETG) was created – the first formal iteration of what was to become ICRT.</p> </li>
      <li><span>1974</span> <p>ETG comprised 12 consumer organisations, including Austria, Belgium, Denmark, France, Germany, The Netherlands, Norway, Switzerland and the UK.</p> </li>
      <li><span>1982</span> <p>The ETG Secretariat moved to London and the number of joint tests continued to grow.</p>  </li>
      <li><span>1990</span> <p>International Consumer Research & Testing Ltd (ICRT) was created to formalise the arrangements of ETG.</p></li>
      <li><span>1992</span> <p>First ICRT Guidelines issued</p></li>
      <li><span>1995</span> <p>ICRT became a founder member of the Euro NCAP crash test programme</p></li>
      <li><span>1999</span><p>Guido Adriaenssens appointed CEO. ICRT now has 26 members</p></li>
     <li><span>2002</span><p> Nordic Testing Group formed</p></li>
      <li><span>2004</span><p>Consumers Reports (USA) joins ICRT; Asia-Pacific Regional Group met for the first time</p></li>
       <li><span>2007</span><p>Capacity building project in new EU Member States leads to formation of Central & Eastern Europe Regional Group in 2010</p></li>
        <li><span>2008</span><p> Worldwide test on Breakfast Cereals published and joined by 30 partners</p></li>
          <li><span>2010</span><p> Latin NCAP launched</p></li>
          <li><span>2011</span><p> Global NCAP launched</p></li>
          <li><span>2021</span><p>  ICRT has 37 members</p></li>

    </ol>

    <p>We now have an office in London, a subsidiary in Germany and a branch in Denmark. Our team of 12 full-time staff is dedicated to coordinating and supporting member organisations to collaborate in knowledge sharing and testing of consumer products.</p>

<ButtonsArea :buttons="buttons" />
</template>

<script>
import ButtonsArea from "../../../../components/ButtonsArea/_ButtonsArea";

export default {
  name:'History',
  components: {
    ButtonsArea,
  },
  data() {
    return {
      buttons: [
        {
          path:  "/about.html?section=impact",
          name: "Impact",
          class: "left",
        },
         {
          path: "/about.html?section=ceo_and_board",
          name: "CEO & Board",
          class: "right",
        },
      ],
    };
  },
};

</script>
<style lang="scss" scoped>
@import './history.scss';
</style>
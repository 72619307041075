<template lang="en">
 <a :href="path" :class="class"><p>{{name}}</p> <span :class="spanClass()"></span> </a>
</template>

<script>
export default {
  name: 'LinkButton',
  methods : {
    spanClass() {
    const classSpan = this.class;
    if(classSpan === "right"){
        return "spanRight";
      } else if(classSpan === "left") {
        return "spanLeft";
      }else if(classSpan === "normal") {
        return "spanNormal";
      }
    }
    
  },
  props: { 
    path: {
      type: String,
    },
    name: {
      type: String,
    },
    class: {
      type: String
    }
  },
 
}
</script>

<style lang="scss" scoped>
  @import './linkButton.scss';
</style>
<template lang="en">
<h2>Our vision</h2>
<p>
  ICRT’s vision is that consumers worldwide have access to transparent and fair marketplaces where they can make smart, safe, secure and sustainable choices.
</p>
<p>This vision is far from accomplished today. Consumer organisations continue to identify and intervene in opaque and unfair marketplaces and consumers must navigate unsafe and insecure products and services. Some consumers, particularly in emerging countries, have little protection against threats as they have ill-funded consumer organisations to protect them, or in some cases none at all. ICRT’s vision is, thus, an aspirational end goal. </p>
<h3>The ability of consumers to ‘make smart choices’ requires at least three conditions to be fulfilled: </h3>

<ol class="pages-ol">
<li><span>1</span><p>That consumers have a choice of products and services.</p></li>
<li><span>2</span><p>That the product and service marketplace is accessible, transparent and fair enough to make this choice meaningful</p></li>
<li><span>3</span><p>That consumers have clear, unbiased information about products and services, including value trade-offs, to help them make smart choices for their specific situations and needs.</p> </li>
</ol>

<p>A smart choice in a fair marketplace also means that consumers can balance their specific needs with the wider consequences of their consumption. This includes the impact their choices may have on the unsustainable use of natural resources, pollution, socio-economic inequality, and climate change.</p>
<p>Finally, an aspiration in the vision is that products and services must be ‘safe and secure’, both in the short-term and the long term. By this, we mean that no product or service should expose consumers to danger or risks, physical or otherwise, and connected devices must protect consumers’ personal data and privacy. </p>
<p>Consumers must be protected from these risks and dangers by safe and secure product and service design, with appropriate data privacy and data security measures built in by default. </p>

<h2>Our mission</h2>
<p>ICRT’s mission is to increase the financial sustainability and impact of independent consumer organisations through global collaboration.</p>
<p>This mission reminds us of ICRT’s purpose and outlines what will bring us closer to our vision. By working only with ‘independent consumer organisations’, which are autonomous and unbiased by manufacturers, retailers, political parties and authorities, we ensure that our research and testing will help us to deliver against our vision. </p>
<h3>Our mission breaks down into two key parts:</h3>
<ul class="pages-ul">
  <li><p><strong>‘Increase the financial sustainability’</strong> of these independent consumer organisations. We do this by enabling members to reduce costs through joint testing, thereby increasing their likelihood to survive and to fulfil their own overarching missions.</p></li>
  <li><p><strong>‘Increase the impact’</strong> of consumer organisations. We want to ensure that our members are able to have an increasingly positive effect on outcomes for consumers by influencing the marketplace, including manufacturers, retailers, lawmakers, regulators, authorities, and consumers themselves.</p>
</li>
</ul>
<p>ICRT’s mission is accomplished through global collaboration between independent consumer organisations that are members of, or associated with, ICRT. Global collaboration among consumer organisations is pivotal for reaching consumers worldwide and for enabling them to make smart, safe and secure consumer choices.</p>

<ButtonsArea :buttons="buttons" />
</template>

<script>
import ButtonsArea from "../../../../components/ButtonsArea/_ButtonsArea";

export default {
  name:'MissionVision',
  components: {
    ButtonsArea,
  },
  data() {
    return {
      buttons: [
        {
          path: "/about.html",
          name: "Who we are",
          class: "left",
        },
         {
          path: "/about.html?section=impact",
          name: "Impact",
          class: "right",
        },
      ],
    };
  },
};

</script>
<style lang="scss" scoped>
@import './missionvision.scss';
</style>
<template lang="en">
  
<div class="buttons">
<LinkButton @click.native="scrollToTop"  v-for="button in buttons" :path="button.path" :name="button.name" :class="button.class"/>
</div>
</template>

<script>
import LinkButton from "../LinkButton/_LinkButton";

  export default {
    name: "ButtonsArea",
  components: {
    LinkButton,
  },
  props: {
    buttons: {
      type: Array,
      required: true,
    },
  },
   methods: {
      scrollToTop(){
        let currentScroll = document.documentElement.scrollTop,
          int = setInterval(frame, 6)
          
          function frame(){
              if( 0 > currentScroll)
                  clearInterval(int)
              else {
                  currentScroll = currentScroll - 12
                  document.documentElement.scrollTop = currentScroll
              }
          }
      }
    }
};
</script>

<style lang="scss" scoped>
  @import './buttonsArea.scss';

</style>
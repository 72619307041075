<template lang="en">
<div class="board__profile">
    <div class="profile_image">
          <div class="image_wrapper">
            <img :src="require( `../../../../assets/images/governance/${profile.image}`)" :alt="profile.name">
          </div>
          <div class="job_title">
            <p v-for="jobTitle in profile.jobTitle">{{jobTitle}}</p>
          </div>
    </div>
    <div class="description">
        <p v-for="paragraph in profile.description">
          {{paragraph}}
        </p>
    </div>
</div>
</template>

<script>
export default {
  name:"BoardProfile",
  props: ["profile", "isProfileVisible"],
  data(){
    return {
      nameAim:"enter",
    }
  }
}
</script>
<style lang="scss">
  @import "./boardprofiles.scss";
</style>
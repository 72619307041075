<template>
<Header hrefTo="#mainView"/>
<Main class="pageNotFound" heading="404 Page Not Found">
  <main-view :isContactPage="true">
  <h2>The page that you are looking for could not be found</h2>  
  <p>Sometimes pages move or become out of date and are deleted, so older bookmarks may no longer work. It's possible that the page you were looking for has either changed name or is no longer available.</p>
  <p class="pNotFound">Please, visit our <a href="/index.html">Home Page</a></p>
 </main-view>
</Main>
<Footer :isHomePage="true" />
</template>

<script>
import Header from "../../components/Header/_Header.vue";
import Main from "../../components/Main/_Main.vue";
import MainView from "../../components/MainView/_MainView.vue"
import Footer from "../../components/Footer/_Footer";

export default {
  name: "PageNotFound",
  components: {
    Header,
    Main,
    MainView,
    Footer,
  },
};
</script>

<style lang="scss">
  @import "./pagenotfound.scss";
</style>

<template lang="en">
    <h2>Who we are</h2>
     <div class="graphic_wrapper">
      <img img v-show="isHorisontal === true" class="horisontal" src="../../../../assets/images/infographicWhoWeAreHorizontal.png" alt="Infographic">
      <img v-show="isHorisontal===false" class="vertical"  src="../../../../assets/images/infographicWhoWeAreVertical.png" alt="Infographic">
      </div>
<p>
  International Consumer Research & Testing (ICRT) is a global consortium of consumer organisations that collaborate on research and testing of consumer products and consumer services. We are a not-for-profit organisation that is fully owned by our members. As such we are independent of product manufacturers, and act exclusively in the interest of consumers.
</p>
<p>
Today, we have 30+ member organisations in almost as many countries, reaching 25M+ consumers monthly through members’ publications and media channels.
</p>
<p>
  We provide our members with the framework and tools to share resources for the joint testing of consumer products. We set clear guidelines for successful and cost-efficient collaboration, allowing members to test and publish more. This in turn enables consumers to make more informed choices when purchasing.
</p>
<h3>Our objectives are to:</h3>
<ul class="pages-ul">
  <li><p>Facilitate cooperation between our members</p> </li>
  <li><p>Promote the research and testing of consumer goods and services</p></li>
  <li><p>Actively support innovation and growth among consumer organisations</p></li>
</ul>

<p>We test a vast range of popular consumer products in sectors including domestic appliances, cars and mobility, electronics, personal care, gardening and DIY, energy, leisure and child products.</p>
<p>By testing jointly with the support of ICRT, members can save around 30-99% compared with conducting the same tests in isolation.</p>

<h2>Company structure</h2>

<h3 style="margin-top: 20px;">ICRT Ltd</h3>

<ul style="margin-left: 12px">
  <li><p> <span style="font-weight: 900;">Full official name:</span> International Consumer Research and Testing Limited.   </p></li>
  <li><p> <span style="font-weight: 900;">Official legal form:</span> Private Limited Company  </p></li>
  <li><p> <span style="font-weight: 900;">Company Number:</span> 02447570   </p></li>
  <li><p>  <span style="font-weight: 900;">Official address:</span>  Oxford House, 49a Oxford Road, London, United Kingdom, N4 3EY   </p></li>
  <li><p><span style="font-weight: 900;">Ownership:</span> ICRT Ltd is fully owned by its members who are independent consumer organisations   </p></li>
  <li><p><span style="font-weight: 900;">Non-profit status:</span> ICRT Ltd is validated as a non-profit organisation by the European Commission  </p></li>
</ul>

<h3 style="margin-top: 20px;">ICRT GmbH</h3>

<ul  style="margin-left: 12px">
  <li><p>  <span style="font-weight: 900;">Full official name:</span> ICRT GmbH   </p> </li>
  <li><p> <span style="font-weight: 900;">Official legal form:</span> GmbH (company with limited liability)   </p></li>
  <li><p>  <span style="font-weight: 900;">Statutory registration number:</span> HRB 96952   </p></li>
  <li><p> <span style="font-weight: 900;">Official address:</span> c/o Regus, Kokkolastraße 5-7, 40882 Ratingen, Germany   </p></li>
  <li><p><span style="font-weight: 900;">Ownership:</span>  ICRT GmbH is an independent subsidiary owned 100% by ICRT Ltd   </p></li>
  <li><p><span style="font-weight: 900;">Non-profit status:</span> ICRT GmbH is validated as a non-profit organisation by the European Commission  </p></li>
</ul>


<ButtonsArea :buttons="buttons" />
</template>

<script>
import ButtonsArea from "../../../../components/ButtonsArea/_ButtonsArea";

export default {
  name:'WhoWeAre',
  components: {
    ButtonsArea,
  },
  data() {
    return {
      buttons: [
        {
          path: "/about.html?section=vision_and_mission",
          name: "Mission & Vision",
          class: "right",
        }
      ],
      width: window.innerWidth,
      isHorisontal:null,
    };
  },
   watch: {
    $width(width){
    },
    $isHorisontal(isHorisontal){}
  },
  created(){
        if (this.width>680) {
              this.isHorisontal=true;
            } else {
              this.isHorisontal=false;
            }
  },
  mounted() {
     window.addEventListener('resize', this.onResize);
    
  },
    methods: {  
        onResize() {
            this.width = window.innerWidth;
           if (this.width>680) {
              this.isHorisontal=true;
            } else {
              this.isHorisontal=false;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
@import './whoWeAre.scss';
</style>
<template lang="en">
<!-- Page Sub-Heading -->
<h2>CEO & Board</h2>
<p>The ICRT board comprises six members, representing key member organisations. </p>
<!-- Section ICRT CEO -->
<section class="ceo">
  <h3>ICRT CEO</h3>
    <ul >
      <li v-for="profile in filterGovernance('ceo')"  :key="profile.id" @click="openCloseProfile($event)" >
        <div class="heading">
            <span>
            <img :src="require(`../../../../assets/images/membersLogos/${profile.logo}`)" :alt="profile.jobTitle">
            </span>
          <div class="info">
              <h4> {{profile.name}} </h4>
              <p>{{profile.from}}</p>
          </div>
        </div>
        <span class="icon"></span>
        <board-profile role="article" :profile="profile" />
      </li>
    </ul>
</section>
<!-- Section ICRT Board -->
<section class="board">
  <h3>Board</h3>
    <ul>
     <li v-for="profile in filterGovernance('board')"  :key="profile.id" @click="openCloseProfile($event)" >
        <div class="heading">
            <span>
            <img :src="require(`../../../../assets/images/membersLogos/${profile.logo}`)" alt="">
            </span>
          <div class="info">
            <h4> {{profile.name}} </h4>
            <p>{{profile.from}}</p>
          </div>
       </div>
      <span class="icon"></span>
      <board-profile :profile="profile" />
      </li>
    </ul>
</section>
<buttons-area :buttons="buttons" />
</template>

<script>
import ButtonsArea from "../../../../components/ButtonsArea/_ButtonsArea";
import BoardProfile from "../../Components/BoardProfiles/_BoardProfiles.vue";
import governanceData from "./governance.json";

export default {
  name:'Board',
  components: {
    ButtonsArea,
    BoardProfile,
  },
  data() {
    return {
      isProfileVisible:null,
      buttons: [
        {
          path: "/about.html?section=financial_information",
          name: "Financial Information",
          class: "right",
        },
         {
          path: "/about.html?section=history",
          name: "History",
          class: "left",
        },
      ],
      governance: governanceData,
    };
  },
  methods: {
    openCloseProfile(event){
      if (event.currentTarget.classList.contains("open")){
          event.currentTarget.classList.remove("open");
          this.scrolling(event.currentTarget)
      } else {
         event.currentTarget.classList.add("open")
      }
    },
    scrolling(element){
      let scrollTo = element.offsetTop - (element.offsetTop/2);
      window.scroll({top:`${scrollTo}`,left:0,  behavior: 'smooth'});
    },
    filterGovernance(type){
        return this.governance.filter(governar=> governar.type===type)
    }
  }
};
</script>

<style lang="scss" scoped>
@import './board.scss';
</style>
<template lang="en">
<!-- Page Sub Heading -->
  <h2>Shareholders</h2>
<!--  <p>To become an ICRT member, organisations must act exclusively in the consumer interest. Our members do not accept advertising and are independent of commerce, industry and political parties.</p>
  <p>Member organisations range in size from the largest consumer organisations worldwide, with memberships between 0.2m and 5m, to smaller organisations with less than 10,000 members.</p>
&lt;!&ndash; Filters Section of the Page &ndash;&gt;-->
<div class="filters">
    <div class="custom-select">
      <p>Sort By:</p>
          <select id="sortBy" v-model="seletedSortBy">
           <option v-for="item in sortBy" > {{item}}</option>
          </select>
    </div>
    <div class="custom-select">
      <p>Filter By:</p>
      <select id="filterBy" v-model="selectedFilterBy">
           <option v-for="item in filterBy"> {{item}}</option>
      </select>
    </div>
</div>
<!-- - - - - - - - - - - - - - - - -->
<!-- A Shareholders -->
    <section class="members__section">
      <div class="heading">
        <h3>A Shareholders</h3>
      </div>
      <p class="ifEmpty" v-show="filterMembers('A',this.seletedSortBy, this.selectedFilterBy).length === 0"> ICRT does not have "A Shareholders" from {{selectedFilterBy}}</p>
      <ul class="members-ul">
        <li v-for="org in filterMembers('A',this.seletedSortBy, this.selectedFilterBy)" :key="org.id">
            <member :org="org"/>
        </li>
      </ul>
    </section>
<!-- B Shareholders -->
      <section class="members__section">
       <div class="heading">
         <h3> B Shareholders </h3> 
       </div> 
      <p class="ifEmpty" v-show="filterMembers('B',this.seletedSortBy, this.selectedFilterBy).length === 0"> ICRT does not have "B Shareholders" from {{selectedFilterBy}}</p>
      <ul class="members-ul">
        <li v-for="org in filterMembers('B',this.seletedSortBy, this.selectedFilterBy )" :key="org.id" >
           <member :org="org"/>
        </li>
      </ul>
    </section>
<!-- C Shareholders -->
  <section class="members__section">
       <div class="heading">
         <h3> C Shareholders</h3> 
       </div> 
      <p class="ifEmpty" v-show="filterMembers('C',this.seletedSortBy, this.selectedFilterBy).length === 0"> ICRT does not have "C Shareholders" from {{selectedFilterBy}}</p>
      <ul class="members-ul">
        <li v-for="org in filterMembers('C',this.seletedSortBy, this.selectedFilterBy )" :key="org.id" >
          <member :org="org"/>
        </li>
      </ul>
    </section>
<!-- Buttons Area at the Bottom of the Page -->
<ButtonsArea :buttons="buttons" />
</template>

<script>
import Member from "./Member/_Member.vue";
import ButtonsArea from "../../../../components/ButtonsArea/_ButtonsArea";
import membersData from "../../../../members.json";

export default {
  name:'OurMembers',
  components: {
    ButtonsArea,
    Member,
  },
  data() {
    return {
      members: membersData,
      buttons: [
        {
          path: "/members.html",
          name: "Members Categories",
          class: "left",
        },
        {
          path: "/members.html?section=icrt_non_shareholders",
          name: "Non Shareholders",
          class: "right",
        },
      ],
      seletedSortBy: "Country (A-Z)",
      sortBy:["Country (A-Z)", "Country (Z-A)", "Name (A-Z)", "Name (Z-A)"],
      selectedFilterBy:"All",
      filterBy:["All", "Europe", "Americas", "Asia/ Oceania", "Nordic (N)"],
    };
  },
methods: {
    filterMembers(type, sortBy, filterBy) {
      if(sortBy === "Name (A-Z)"){
        console.log()
        return this.members.filter(org => org.type === type).sort(function(a, b){
                  if(a.name < b.name) { return -1; }
                  if(a.name > b.name) { return 1; }
                  return 0;
            }).filter(org => {
                if(filterBy === "All"){
                return org
                } else if(org.region === filterBy) {
                 return org;
              }
            });
      } else if (sortBy === "Name (Z-A)") {
            return this.members.filter(org => org.type === type).sort(function(b, a){
                  if(a.name < b.name) { return -1; }
                  if(a.name > b.name) { return 1; }
                  return 0;
            }).filter(org => {
                if(filterBy === "All"){
                return org
                } else if(org.region === filterBy) {
                 return org;
              }
            });
       } else if (sortBy === "Country (A-Z)") {
            return this.members.filter(org => org.type === type).sort(function(a, b){
                  if(a.country < b.country) { return -1; }
                  if(a.country > b.country) { return 1; }
                  return 0;
            }).filter(org => {
                if(filterBy === "All"){
                return org
                } else if(org.region === filterBy) {
                 return org;
              }
            });
       } else if (sortBy === "Country (Z-A)") {
            return this.members.filter(org => org.type === type).sort(function(b, a){
                  if(a.country < b.country) { return -1; }
                  if(a.country > b.country) { return 1; }
                  return 0;
            }).filter(org => {
                if(filterBy === "All"){
                return org
                } else if(org.region === filterBy) {
                 return org;
              }
            });
       }
    },
}
};
</script>

<style lang="scss">
@import './ourMembers.scss';
</style>
<template lang="en">
<!-- Member List Item -->
    <div class="member">
      <!-- Logo -->
            <a class="logo-wrapper" :href="org.socialMedia[0].socialLink" target="_blank">
              <img :src="require(`../../../../../assets/images/membersLogos/${(org.abr).toLowerCase()}.png`)" :alt="org.name">
            </a>
            <!-- Flag + Country -->
          <div class="country">
            <img :src="require(`../../../../../assets/images/flags/${(org.country).split(' ').join('').toLowerCase()}.png`)" :alt="org.country + ' ' + 'flag'">
            <p>{{org.country}}</p>
          </div>
          <!-- Social Media Accounts Section -->
          <div class="social-media">
            <ul class="social-media-ul">
              <li v-for="link in org.socialMedia">
                <a v-if="link.socialName==='website'" :href="link.socialLink" class="website" target="_blank"></a>
                <a v-else-if="link.socialName==='twitter'" :href="link.socialLink" class="twitter" target="_blank"></a>
                <a v-else-if="link.socialName==='linkedin'" :href="link.socialLink" class="linkedin" target="_blank"></a>
                <a v-else-if="link.socialName==='youtube'" :href="link.socialLink" class="youtube" target="_blank"></a>
              </li>
            </ul>
          </div>
          <!-- Bottom Section (ICRT Member Since) -->
          <!-- <div class="member-since">
            <p>Member since {{org.memberSince}}</p>
          </div> -->
        </div>
</template>

<script>
export default {
  name:"Member",
  props:["org"],
}
</script>

<style lang="scss">
  @import "./member.scss";
</style>
<template lang="en">
    <h2>What we do</h2>
    <div class="graphic_wrapper">
        <img img v-show="isHorisontal === true" class="horisontal" src="../../../../assets/images/infographicWhatWeDoHorizontal.png" alt="Infographic">
      <img v-show="isHorisontal===false" class="vertical"  src="../../../../assets/images/infographicWhatWeDoVertical.png" alt="Infographic">
      </div>
    <p>ICRT enables national consumer organisations to join forces when they carry out consumer research and test consumer products. Joint testing allows members to share costs; ICRT can deliver savings to members of around 30-99% compared with them conducting the same tests in isolation. This results in:</p>
    <ul class="pages-ul">
      <li><p>Higher-quality tests and research</p></li>
      <li><p>The ability for each organisation to test a greater number of products</p> </li>
      <li><p>Budget retained to spend on other priorities</p> </li>
    </ul>
    <p>This in turn supports consumer organisations in their goal to empower consumers to make informed purchasing decisions. </p>
    <p>We also help our members to design test programmes and facilitate the sharing of expertise and important test findings. 
    </p>
    <p>
      We organise regular meetings to coordinate planning, joint approach and collaboration on various aspects of sustainability and renewable energy technologies. And provide regional programmes to support member organisations from different geographical regions focusing on regional aspects and priorities.
    </p>
<ButtonsArea :buttons="buttons" />
</template>

<script>
import ButtonsArea from "../../../../components/ButtonsArea/_ButtonsArea";

export default {
  name:'WhatWeDo',
  components: {
    ButtonsArea,
  },
  data() {
    return {
      buttons: [
         {
          path: "/work.html?section=product_testing" ,
          name: "Product Testing",
          class: "right",
        },
      ],
      width: window.innerWidth,
      isHorisontal:null,
    };
  },
   watch: {
    $width(width){
    },
    $isHorisontal(isHorisontal){}
  },
  created(){
        if (this.width>680) {
              this.isHorisontal=true;
            } else {
              this.isHorisontal=false;
            }
  },
  mounted() {
     window.addEventListener('resize', this.onResize);
    
  },
    methods: {  
        onResize() {
            this.width = window.innerWidth;
           if (this.width>680) {
              this.isHorisontal=true;
            } else {
              this.isHorisontal=false;
            }
        }
    }
};

</script>
<style lang="scss" scoped>
@import './whatWeDo.scss';
</style>
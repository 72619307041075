<template>
<Header hrefTo="#mainView"/>
<Main heading="Privacy Policy">
  <main-view :isContactPage="true">
      <small class="small">Last updated on 1 October 2022.</small>
      <p>ICRT respects the privacy of its employees, members, experts, and suppliers and is committed to managing their personal data in strict confidentiality. We collect, use and store the personal data transmitted to us in accordance with the General Data Protection Regulation (GDPR).</p>
      <p>We will only use personal information when we have a fair and lawful basis for doing so. As a membership organisation, most of the personal information we process is done so under the auspices of our legitimate business interests or where it is necessary to perform a contract with our members or staff. </p>
      <p>We do not collect cookies or any personal information about individual visitors to our website
        <a href="https://www.international-testing.org/">(www.international-testing.org).</a></p>
  <h3>Our contact details</h3>
    <p>If you have any questions about our data policy you can contact us using the details below.</p>
    <table>
      <tr>
        <td class="larger">Data Protection Officer:</td>
        <td>International Consumer Research and Testing Ltd</td>
      </tr>
      <tr>
        <td class="larger">Address:</td>
        <td>Oxford House, 49a Oxford Road, London. N4 3EY</td>
      </tr>
      <tr>
        <td class="larger">Phone Number:</td>
        <td>+ 44 (0) 207 563 9170</td>
      </tr>
      <tr>
        <td class="larger">E-mail:</td>
        <td>helpdesk@international-testing.org</td>
      </tr>
    </table>




  </main-view>
</Main>
<Footer :isHomePage="true" />
</template>

<script>
import Header from "../../components/Header/_Header.vue";
import Main from "../../components/Main/_Main.vue";
import MainView from "../../components/MainView/_MainView.vue"
import Footer from "../../components/Footer/_Footer";

export default {
  name: "PrivacyPolicy",
  components: {
    Header,
    Main,
    MainView,
    Footer,
  },
};
</script>

<style lang="scss">

@import '../members/views/MembersCategories/memberships';

.small {
  margin-bottom: 2rem;
}

</style>








      
      
      
    
<template>
<Header hrefTo="#whoweare" :classHome="true" :isActive="isActive" :homePage="true"/>
<Main :isHomePage="true">
    <banner-home />
    <who-we-are-home />
    <what-we-do-home />
    <global-impact-home />
    <testemonials />
</Main>
<Footer :isHomePage="true"/>
<!-- <cookies-mesage /> -->
</template>

<script>
import { useGtag } from "vue-gtag-next";
import Header from "../../components/Header/_Header.vue";
import Main from "../../components/Main/_Main.vue";
import BannerHome from "./Components/Banner/_BannerHome.vue";
import WhoWeAreHome from "./Components/WhoWeAreHome/WhoWeReHome.vue";
import WhatWeDoHome from "./Components/WhatWeDoHome/WhatWeDoHome.vue";
import GlobalImpactHome from "./Components/GlobalImpactHome/GlobalImpactHome.vue";
import Testemonials from "./Components/Testemonials/_Testemonials.vue";
import Footer from "../../components/Footer/_Footer.vue";
import CookiesMesage from "../../components/Cookies/_CookiesMessage.vue";

export default {
  name: "Home",
  data() {
    return {
      isActive:false,
    }
  },
  components: {
    Header,
    Main,
    BannerHome,
    WhoWeAreHome,
    WhatWeDoHome,
    GlobalImpactHome,
    Testemonials,
    Footer,
    CookiesMesage,

  },
mounted() {
    window.addEventListener('scroll', this.changeColor);
   },
methods: {
    changeColor() {
    if(window.scrollY > 100){
        this.isActive = true;
      } else {
        this.isActive = false;
      }
  }
}
};
</script>

<style scoped lang="scss">
    @import "./home.scss";
</style>
<template lang='en'>
<div class="nav container">
<!-- M O B I L E * N A V I G A T I O N - - - - - - - - - -->
  <nav>
    <!-- LOGO 
  
    -->
    <!-- !!!!! Also added to styling padding and width chnaged from 100px  -->
     <a aria-label="Home" href="/index.html">
        <img src="../../assets/images/logoShortWhite.png" alt="ICRT Logo">
      </a>
    <!-- M E N U * B U T T U N * * *  -->
      <button v-on:keyup.esc="isVisibleNav" :aria-pressed="isVisible" :aria-expanded="isVisible" aria-label="Toggle Navigation"  @click="isVisibleNav" :class="[isVisible ? 'burger_menu open' : 'burger_menu']">
          <p>Menu</p>
          <span ></span>
      </button>
<!-- M E G A * M E N U - - - - - - - - - - -->
    <div ref="menu" class="menu">
      <!-- M e n u * H e a d e r - - - - - -->
        <div ref="menuHead" class="mobile-menu-head">
            <div class='go-back' @click="hideSubMenu"><span class="arrow"></span> </div>
            <div ref="currentTitle" class="current-menu-title">{{currentTitle}}</div>
        </div>
<!-- * * * M E N U * C A T E G O R I E S * * * -->
        <ul role="menubar"  ref="list" class="main-menu" :class="{visible: isVisible}" >
 <!--L I S T * I T E M * * * * * * H O M E - - - - - - - - - - - - - -- - - - - - - - - -->
            <li role="none">
              <a href="/index.html">Home</a>
            </li>
 <!--L I S T * I T E M * * * * * * A B O U T - - - - - - - - - - - - - -- - - - - - - - - -->
            <li role="none" class="menu-item-has-children">
              <a href="#" 
                role="menuitem"
                aria-haspopup="true"
                aria-expanded="false" 
                @click="showSubMenu('about')"> 
                About 
                <span class="arrow"></span>
              </a>
        <!-- A B O U T * * * * * * * * * * * S U B * M E N U * * * -->
                <div role="none" ref="subMenu-about" class="sub-menu">
                  <ul role="menu">
                    <li v-for="page in pages.about" role="none">
                      <a class="navLink" v-on:keyup.esc="hideSubMenu" :href="page.path"><span class="arrow"></span> {{page.name}}</a>
                    </li>
                  </ul>
                </div>
             </li>
<!--L I S T * I T E M * * * * * * * * * * * O U R * W O R K - - - - - - - - - - - - - - - - - - -->
          <li role="none" class="menu-item-has-children">
              <a href="#" 
                role="menuitem"
                aria-haspopup="true"
                aria-expanded="false" 
                @click="showSubMenu('ourwork')"> 
                Our Work 
                <span class="arrow"></span></a>
        <!--O U R * W O R K * * * * * * * * * * * S U B * M E N U * * * -->
                <div role="none" ref="subMenu-ourwork" class="sub-menu">
                  <ul role="menu">
                    <li v-for="page in pages.work"  role="none">
                      <a class="navLink" :href="page.path"><span class="arrow"></span>{{page.name}}</a>
                    </li>
                  </ul>
                </div>
            </li>
 <!--L I S T * I T E M * * * * * * M E M B E R S - - - - - - - - - - - - - - - - - - - -->
          <li role="none" class="menu-item-has-children">
          <a href="#" 
              role="menuitem"
              aria-haspopup="true"
              aria-expanded="false" 
              @click="showSubMenu('members')"> 
              Members 
              <span class="arrow"></span>
          </a>
        <!--M E M B E R S * * * * * * * * * * * S U B * M E N U * * * -->
            <div role="none" ref="subMenu-members"  class="sub-menu">
              <ul role="menu">
                <li v-for="page in pages.members" role="none" :class="page.classNav">
                  <a class="navLink"  :href="page.path"><span class="arrow"></span>{{page.name}}</a>
                </li>
              </ul>
            </div>
          </li>
 <!--L I S T * I T E M * * * * * * C O N T A C T - - - - - - - - - - - - - - - - - - - -->
          <li role="none"><a href="/contact.html">Contact</a></li>
 <!--L I S T * I T E M * * * * * *S O C I A L * M E D I A - - - - - - - - - - - - - - -->
          <li class="social_media">
           <a class="linkedin" href="https://www.linkedin.com/company/international-consumer-research-&-testing-ltd" target="_blank"></a>
          </li>
        </ul>
      </div>
  </nav>
</div>
</template>

<script>
import pagesData from '../../pages/pages.json';

export default {
  name: "MobileNavigation",

  data() {
    return {
      isVisible:false,
      currentTitle:"",
      pages: pagesData,
    }
  },
  methods: {
    isVisibleNav() {
      this.isVisible = !this.isVisible;
      this.$refs.menu.classList.toggle("active")
      if (this.isVisible) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "scroll";
      }
         setTimeout(() =>{
       document.querySelectorAll(".sub-menu").forEach(menu=> {
         menu.classList.remove("active");	
       })
    },300); 
    this.$refs.menuHead.classList.remove("active");
    },
    hideSubMenu(){  
        setTimeout(() =>{
          document.querySelectorAll(".sub-menu").forEach(menu=> {
            menu.classList.remove("active");	
          })
        },300); 
        this.$refs.menuHead.classList.remove("active");
     },

      showSubMenu(name){
        this.activeLink();
          this.$refs[`subMenu-${name}`].classList.add("active");
          this.currentTitle =  name;
          this.$refs.menuHead.classList.add("active");
          },
    activeLink(){
        const currentLocation = location.href;
        document.querySelectorAll(".navLink").forEach(link=>{
          if(link.href===currentLocation){
            link.classList.add('activeLink');
          } else {
            link.classList.remove('activeLink');
          }
    })
  },
},
};
</script>

<style lang="scss" scoped>
@import "./mobileNavigation.scss";
</style>



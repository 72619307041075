<template lang="en">
    <h2>Survey & Services</h2>
    <p>We facilitate a network of surveys and services experts from our member organisations to discuss and exchange methodologies and structure of multinational surveys on subjects ranging from product reliability, product life expectancy, consumer behaviour and attitudes, sustainability, food and health and other topics of common interest.</p>
    <p>The outcome of the survey research can be published in our members’ publications, fed into the design of consumer-centric test programmes and evaluations, support policy and advocacy actions as well as providing input into our overall test portfolio.</p>
    <p>We cover multiple different areas, with some of our most recent projects being related to Animal Welfare and the Green Claims Directive.</p>
<ButtonsArea :buttons="buttons" />
</template>

<script>
import ButtonsArea from "../../../../components/ButtonsArea/_ButtonsArea";

export default {
  name:'SurveyServices',
  components: {
    ButtonsArea,
  },
  data() {
    return {
      buttons: [
        {
          path: "/work.html?section=product_testing",
          name: "Product Testing",
          class: "left",
        },
         {
          path: "/work.html?section=research_projects",
          name: "Research Projects",
          class: "right",
        },
      ],
    };
  },
};

</script>
<style lang="scss" scoped>
@import './surveyServices.scss';
</style>
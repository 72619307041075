<template lang="en">
<footer :class="{homeFooter: isHomePage}">
  <div class='footer container' >
    <div class="content">
      <!--  <img src="../../assets/images/logoShortWhite.png" alt="ICRT Logo">-->
         <a class="logo" href="/index.html">
           <img src="../../assets/images/logoShortWhite.png" alt="ICRT Logo">
         </a>
      <nav @click="openDropDown($event)" class="footer-nav hasChildren">
        <h3 >Categories <span class="plus"></span></h3>
        <ul>
          <li><a class="link"  href="/index.html"><span class="arrow"></span>Home</a></li>
          <li><a class="link"  href="/about.html"><span class="arrow"></span>About</a></li>
          <li><a class="link"  href="/work.html"><span class="arrow"></span>Our Work</a></li>
          <li><a class="link"  href="/members.html"><span class="arrow"></span>Members</a></li>
          <li><a class="link"  href="/contact.html"><span class="arrow"></span>Contact</a></li>
      </ul>
      </nav>
      <div @click="openDropDown($event)" class="useful-links hasChildren">
           <h3>Useful Links <span class="plus"></span> </h3> 
            <ul>
              <li><a class="link"  href="/accessibility.html"><span class="arrow"></span>Accessibility Statement</a></li>
              <!-- <li><a class="link"  href="/sustainability.html"><span class="arrow"></span>Sustainability Report</a></li> -->
              <!-- <li><a class="link"  href="/ourwork.html"><span class="arrow"></span>F&Q</a></li> -->
              <li><a class="link"  href="/sitemap.html"><span class="arrow"></span>Sitemap</a></li>
              <li><a class="link"  href="/privacyPolicy.html"><span class="arrow"></span>Privacy Policy</a></li>
              <li><a class="link"   href="/docs/Gender_Equality_Plan_ICRT_v1.pdf" download="Gender_Equality_Plan_ICRT_v1.pdf"><span class="arrow"></span>Gender Equality Plan </a></li>
          </ul>
      </div>
      <address  class="address hasChildren" @click="openDropDown($event)">
        <h3>Contact us <span class="plus"></span> </h3>
            <ul class="address_ul">
              <li><span class="icon location"></span> <p>49a Oxford Road, N4 3EY, London</p></li>
              <li><span class="icon phone"></span> <a href="tel:00442075639170">+ 44 (0) 207 563 9170</a></li>
              <li><span class="icon email"></span><p>secretariat@international-testing.org</p></li>
              <li> 
                <ul class="address__social-media">
                        <li> <p class='LinkedInTransition'>Follow us </p> <a aria-label="linkedin" class="linkedin" href="https://www.linkedin.com/company/international-consumer-research-&-testing-ltd" target="_blank"></a></li>
                </ul>
            </li>
            </ul>
      </address>
    </div>
  </div>
  <div class="bottom-info">
      <ul class="social-media-links">
        <li><p>Follow us </p><a aria-label="linkedin" class="linkedin" href="https://www.linkedin.com/company/international-consumer-research-&-testing-ltd" target="_blank"></a></li>
       
      </ul>
      <p>ICRT GmbH  Registration number: HRB96952</p>
      <span> | </span>
      <p>ICRT LTD Registration Number: 02447570</p>
      <p>All Rights Reserved &copy; {{year}} <span>International Consumer Research & Testing Limited</span></p>
    </div>
    </footer>

</template>


<script>

export default {
  name:"Footer",
  data() {
    return {
      year: new Date().getFullYear(),

    }
  },
  props:{
    isHomePage: {
        type: Boolean,
        default:false,
      }
  },
  methods: {
    openDropDown(event){
      const width = window.innerWidth;
      if(width<860){
            document.querySelectorAll(".hasChildren").forEach(listItem=> {
          if(listItem.classList !== event.currentTarget.classList){
            listItem.classList.remove("open");
          } 
        })
         event.currentTarget.classList.toggle("open");
      } else {
      }
    }
  },

}
</script>

<style scoped lang="scss">
  @import './footer.scss';
</style>
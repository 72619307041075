<template lang="en">
    <h2>Membership</h2>
    <p>ICRT has five categories of membership. We will discuss which category is appropriate for you at the introductory meeting. </p>
    <table>
      <tr>
        <th>Membership Category</th>
        <th>Description</th>
      </tr>
      <tr>
        <td>A </td>
        <td>Shareholders in ICRT that appoint a Director to the ICRT Board. Each member has eight votes in a General Assembly Meeting.</td>
      </tr>
        <tr>
        <td>B </td>
        <td>Shareholders in ICRT that may vote on all matters. Each member has one vote in a General Assembly Meeting.</td>
      </tr>
        <tr>
        <td>C </td>
        <td>Shareholders that may participate in ICRT work on a more limited basis, but have the right to vote on certain issues.</td>
      </tr>
        <tr>
        <td>Candidate</td>
        <td>Entry membership status at a B- or C-tier without yet being shareholders of ICRT. Candidate members may later become shareholders of ICRT.</td>
      </tr>
        <tr>
        <td>Affiliate</td>
        <td>Non-shareholders in ICRT that contract to join ICRT projects for a fixed duration.</td>
      </tr>
    </table>
<ButtonsArea :buttons="buttons" />

</template>
<script>
import ButtonsArea from "../../../../components/ButtonsArea/_ButtonsArea";


export default {
  name:'Memberships',
  components: {
    ButtonsArea,
  },
  data() {
    return {
      buttons: [
         {
          path: "/members.html?section=become_a_member",
          name: "BecomeMember",
          class: "left",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import './memberships.scss';
</style>
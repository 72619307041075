<template lang="en">
<section class="banner">
  <div class="background_gradient"></div>
    <div class="banner__message">
          <div class="message">
              <h1>
                 Global research and testing in the consumer interest
              </h1>
              <p>
                International Consumer Research & Testing (ICRT) is a global consortium of consumer organisations that collaborate on research and testing of consumer products and services.
              </p>
              <div class="links">
                  <a class="aboutUs" href="/about.html"
                    >Learn more about us <span class="arrow"></span>
                  </a>
                  <!-- <a class="watchVideo" href="#whoweare">
                    <span class="play"></span> <p>Watch video</p>
                  </a> -->
              </div>
       
          </div>
          <div class="scroll-container">
            <div class="line"></div>
            <div class="scroll-wrapper one">
              <div ref="arrow" class="arrow-move">
                  <span class="scroll move"></span>
                  <span class="scroll move"></span>
              </div>    
            </div>
            <p class="scroll-text">Scroll <span>Down</span></p>
            <div class="scroll-wrapper two">
              <div ref="arrowTwo" class="arrow-move">
                  <span class="scroll move"></span>
                  <span class="scroll move"></span>
              </div>    
            </div>
           </div>
                  <div class="social-media-links">
                <ul>
                  <li role="none"> <p>Follow us</p> <a aria-label="linkedin"  class="linkedin" href="https://www.linkedin.com/company/international-consumer-research-&-testing-ltd" target="_blank"></a></li>
                </ul>
              </div>
  </div>   

</section>
</template>

<script>
export default {
  name:"BannerHome",
   mounted() {
    window.addEventListener('scroll', this.removeArrow);
    // setTimeout(function() { 
    // document.querySelector(".line").style.opacity="0";
    // document.querySelector(".scroll-text").style.opacity="0";
    // document.querySelectorAll(".scroll").forEach(arrow =>{
    //   arrow.style.display="none";
    // })
    //  }, 11500);
   },
   methods: {
    removeArrow() {

    if (this.$refs.arrow) {
     if(window.scrollY > 100){
        // this.$refs.arrow.style.display="none";
        // this.$refs.arrowTwo.style.display="none";
        // document.querySelector(".line").style.opacity="0";
        // document.querySelector(".scroll-text").style.opacity="0";
        document.querySelectorAll(".scroll").forEach(scroll => {
          scroll.classList.remove("move");
        })
      } 
    }
  }
 } 
}
</script>

<style scoped lang="scss">
  @import "./bannerhome.scss"
</style>
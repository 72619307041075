<template lang="en">
  <section class="whatwedo">
      <div class="section__conatiner">
        <div class="text_wrapper">
        <h2>What we do</h2>
        <p>Our members publish comparative test results on consumer products to millions of consumers around the world. ICRT provides the tools, framework and structure for consumer organisations to join forces on product testing and research, enabling them to publish more, higher-quality tests.</p>
        <p>By conducting joint tests, members share the cost of testing, allowing them to test with higher quality and publish more for consumers. This in turn empowers consumers to make more informed purchasing decisions. We also help our members to design test programmes and facilitate the sharing of expertise and important test findings. </p>
        <p>We run more than 60 large joint continuous tests and numerous smaller one-off joint tests each year. We use around 50 highly-specialised independent laboratories worldwide to carry out research and tests on thousands of products. </p>
        <p>Our testing encompasses a vast range of popular consumer products in sectors including domestic appliances, cars and mobility, electronics, personal care, gardening and DIY, energy, leisure and child products.</p>
       <link-anchor-button :path="button.path" :name="button.name" :class="button.class"/>
      </div>
      <ul>
        <li class="mobility">
          <img src="../../../../assets/images/topicMobility.webp" alt="Mobility Icon">
        </li>
        <li class="domesticAppliances">
          <img src="../../../../assets/images/topicDomesticAppliances.webp" alt="Domestic Appliances Icon">
        </li>
        <li class="hiTech">
          <img src="../../../../assets/images/topicHiTech.webp" alt="Hi Tech Icon">
        </li>
        <li class="personalCare">
          <img src="../../../../assets/images/topicPersonalCare.webp" alt="Personal CareIcon">
        </li>
        <li class="diyGardening">
          <img src="../../../../assets/images/topicDIYGardening.webp" alt="Gardening Icon">
        </li>
        <li class="energy">
          <img src="../../../../assets/images/topicEnergy.webp" alt="Energy Icon">
        </li>
        <li class="food">
          <img src="../../../../assets/images/topicFood.webp" alt="Food Icon">
        </li>
        <li class="childProducts">
          <img src="../../../../assets/images/topicChildProducts.webp" alt="Child Products Icon">
        </li>
        <li class="leisure">
          <img src="../../../../assets/images/topicLeisure.webp" alt="Leisure Icon">
        </li>
         <li class="domesticChemicals">
           <img src="../../../../assets/images/topicDomesticChemicals.webp" alt="Domestic Chemicals">
           </li>
        <li class="environment">
          <img src="../../../../assets/images/topicSustainability.webp" alt="Environmental Icon">
        </li>
        <li class="surveys">
          <img src="../../../../assets/images/topicSurveys.webp" alt="Surveys Icon">
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import LinkAnchorButton from "../../../../components/LinkAnchorButton/_LinkAnchorButton.vue"
export default {
  name:"WhatWeDoHome",
  components: {
    LinkAnchorButton,
  },
    data() {
    return {
      button:{
          path:"/work.html",
          name:"Learn more about our work",
          class:"normal"
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "./whatwedohome.scss";
</style>
<template lang='en'>
<Accessibility :openMethod="openMethod" :isOpenA11y="isOpenA11y" :hrefTo="hrefTo"  :homepage="classHome"/>
<div class="nav container"  ref='nav' :class="activeNav ? 'active' : classHome ? 'homeNav' : ''">
  <nav>
      <a :class="{longLogo: activeNav === false}" href="/index.html">
        <!--   <img v-show="classHome && !activeNav"  src="../../assets/images/ICRT_cancer_awarness_dark_large.png" alt="ICRT Logo">
        <img v-show="activeNav"  src="../../assets/images/ICRT_cancer_awarness_white_small.png" alt="ICRT Logo">
        <img v-show="!classHome && !activeNav"  src="../../assets/images/ICRT_cancer_awarness_white_large.png" alt="ICRT Logo">  -->

        <img v-show="classHome && !activeNav"  src="../../assets/images/logoLongNavy.png" alt="ICRT Logo">
        <img v-show="activeNav"  src="../../assets/images/logoShortWhite.png" alt="ICRT Logo">
        <img v-show="!classHome && !activeNav"  src="../../assets/images/logoLongWhite.png" alt="ICRT Logo">      

       
      </a>
    <ul>
      <li><a class="link" :class="{activeLink: currentLocation === '/index.html' }"  href="/index.html">Home</a></li>
      <li><a class="link" :class="{activeLink: currentLocation === '/about.html' }"  href="/about.html">About</a></li>
      <li><a class="link" :class="{activeLink: currentLocation === '/work.html' }" href="/work.html">Our Work</a></li>
      <li><a class="link" :class="{activeLink: currentLocation === '/members.html' }" href="/members.html">Members</a></li>
      <li><a class="link" :class="{activeLink: currentLocation === '/contact.html' }" href="/contact.html">Contact</a></li>
      <li v-show="activeNav === false" @click="openA11y" class="acc11y_icon" :class="{lihomepage:classHome,active: isOpenA11y }"><span v-show="isOpenA11y === true" class="close" :class="{homepage:classHome}">Close</span></li>
      <li v-show="classHome===true"><a class="link projectLink"  href="https://projectlink.international-testing.org/login">Member login</a></li>
    </ul>
  </nav>
  </div>
</template>

<script>
import Accessibility from "../AccessibilityOptions/_Accessibility.vue"

export default {
  name: 'Navigation',
  data(){
    return {
      activeNav: false,
      isOpenA11y:false,
      currentLocation:null,
    }
  },
   props: { 
    classHome: {
      type: Boolean,
      default: false,
    },
     hrefTo: {
      type: String,
    },
  },
  components: {
      Accessibility,
  },
  watch: {
     $route(to){
       this.currentLocation = to.fullPath.split('?')[0];
     }
    },
 mounted() {
    window.addEventListener('scroll', this.fixedNav);
 },
methods: {
  fixedNav() {
    this.closeAccessibility();
    if(window.scrollY > 100){
        this.activeNav = true;
      } else {
        this.activeNav = false;
      }
  },
  openA11y() {
    this.isOpenA11y=!this.isOpenA11y;
  },
  openMethod(){
    this.isOpenA11y = true;
  },
  closeAccessibility() {
    this.isOpenA11y=false;
  }
}
}
</script>

<style scoped lang="scss">
  @import './navigation.scss';
</style>



<template lang="en">
<section class="testemonials">
  <div class="heading">
    <h2> Testimonials </h2>
     <div class="carosel-control">
      <button aria-label='play' @click="playCarouselSlides" class="play"></button>
      <button aria-label='stop' @click="stopCarouselSlides" class="pause"></button>
    </div>
  </div>
    <div class="carosel">
        <slide v-for="(testemonial, index) in testemonials" 
              :key="index"
              :testemonial="testemonial"
              :index="index"
              :visibleSlide="visibleSlide"
              :direction="direction" 
              />
    </div>
<div class="dotsNav">
  <ul>
    <li :class="{active: visibleSlide===index}" @click="dotsNav($event, index)" v-for="(testemonial, index) in testemonials"></li>
  </ul>
</div>
  <button aria-label='previous' @click="prevSlide()" class="buttons button__previous"></button>
  <button aria-label='next'  @click="nextSlide('stop')" class="buttons button__next"></button>
</section>
</template>

<script>
import Slide from "./Slide/_Slide.vue"
import testemonialsData from './testemonials.json';

export default {
  name:"Testemonials",
  data(){
    return {
      visibleSlide: 0,
      direction:'left',
      runningSlides: null,
      testemonials: testemonialsData
    }
  },
  components: {
    Slide,
  },
  computed:{
    slidesLength(){
      return this.testemonials.length;
    }
  },
  methods: {
    playCarouselSlides () {
      this.runningSlides = setInterval(() => {
      this.nextSlide("play");
      }, 10000)
    },
   stopCarouselSlides() {
    	clearInterval(this.runningSlides);
    },
    nextSlide(action){

      if(action === "stop"){
      	clearInterval(this.runningSlides);
      }

      if(this.visibleSlide >= this.testemonials.length -1){
          this.visibleSlide = 0;
      } else {
        this.visibleSlide ++;
      }
      this.direction="left";
    },
    prevSlide(){
      if(this.visibleSlide <= 0){
                this.visibleSlide = this.slidesLength - 1;
            } else {
              this.visibleSlide --;
            }
      this.direction="right";
    },
    dotsNav(event,index){
      this.visibleSlide=index;
    	clearInterval(this.runningSlides);
    }
  },
 beforeDestroy () {
	clearInterval(this.runningSlides);
},
created () {
	this.playCarouselSlides();
 }
}
</script>

<style scoped lang="scss">
  @import "./testemonials.scss";
</style>
import { createRouter, createWebHistory } from 'vue-router';
import Home from "../pages/home/Home.vue"
import WhoWeAre from '../pages/about/views/WhoWeAre/_WhoWeAre.vue';
import MissionVision from '../pages/about/views/MissionVision/_MissionVision.vue';
import SuccessStories from '../pages/about/views/SuccessStories/_SuccessStories.vue';
import History from '../pages/about/views/History/_History.vue';
import Board from '../pages/about/views/Board/_Board.vue';
import FinancialInformation from '../pages/about/views/FinanciaInformation/_FinancialInformation.vue';
import Partners from '../pages/about/views/Partners/_Partners.vue';
import OurMembers from '../pages/members/views/OurMembers/_OurMembers.vue';
import BecomeMember from '../pages/members/views/BecomeMember/_BecomeMember.vue';
import Memberships from '../pages/members/views/Memberships/_Memberships.vue';
import ResearchProjects from '../pages/work/views/ResearchProjects/_ResearchProjects.vue';
import SurveyServices from '../pages/work/views/SurveyServices/_SurveyServices.vue';
import Testing from '../pages/work/views/Testing/_Testing.vue';
import WhatWeDo from '../pages/work/views/WhatWeDo/_WhatWeDo.vue';
import PageNotFound from '../pages/PageNotFound/PageNotFound.vue';
import PrivacyPolicy from '../pages/privacyPolicy/privacyPolicy.vue';

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about.html",
    name: "WhoWeAre",
    component: WhoWeAre,
  },
  {
    path: "/about.html?section=mission_and_vision",
    name: "MissionVision",
    component: MissionVision,
  },
  {
    path: "/about.html?section=impact",
    name: "SuccessStories",
    component: SuccessStories,
  },
  {
    path: "/about.html?section=history",
    name: "History",
    component: History,
  },
  {
    path: "/about.html?section=ceo_and_board",
    name: "Board",
    component: Board,
  },
  {
    path: "/about.html?section=financial_information",
    name: "FinancialInformation",
    component: FinancialInformation,
  },
  {
    path: "/about.html?section=partners",
    name: "Partners",
    component: Partners,
  },
  {
    path: "/members.html",
    name: "OurMembers",
    component: OurMembers,
  },
  {
    path: "/members.html/become_a_member",
    name: "BecomeMember",
    component: BecomeMember,
  },
  {
    path: "/members.html/memberships",
    name: "Memberships",
    component: Memberships,
  },
  {
    path: "/work.html/testing",
    name: "Testing",
    component: Testing,
  },
  {
    path: "/work.html/survey_and_services",
    name: "SurveyServices",
    component: SurveyServices,

  },
  {
    path: "/work.html/research_projects",
    name: "ResearchProjects",
    component: ResearchProjects,
  },
  {
    path: "/work.html",
    name: "WhatWeDo",
    component: WhatWeDo,
  },
  {
    path: '/404.html',
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: '/privacyPolicy.html',
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;





<template lang="en">
  <h2>Product Testing</h2>
   <p>ICRT tests more than 5,000 products each year, including:</p>
    <ul class="pages-ul">
      <li><p>More than 60 large, continuous joint tests; ongoing programmes of testing that repeat at regular intervals to account for new and updated products.</p> </li>
      <li><p>Approximately 115 smaller, one-off joint tests.</p></li>
    </ul>
    <p>The consumer products we test span sectors including domestic appliances, cars and mobility, electronics, personal care, gardening and DIY, energy, leisure and child products.</p>
    <p>We also plan and supervise product topic networks to help consumer organisations provide the highest standard of information to their audiences. We facilitate a continuous exchange of information between members on the latest test methodologies, so that organisations can stay up to speed with testing best practice globally.</p>

  <h2>How ICRT Tests</h2>
  <div class="graphic_wrapper">
        <img img v-show="isHorisontal === true" class="horisontal" src="../../../../assets/images/infographicHowWeTestHorizontal.png" alt="Infographic">
      <img v-show="isHorisontal===false" class="vertical"  src="../../../../assets/images/infographicHowWeTestVertical.png" alt="Infographic">
      </div>
    <ul class="pages-ul">
      <li><p><strong>Test programme design:</strong> We develop common test procedures for large continuous tests of products such as mobile phones, washing machines, digital cameras, child car seats and tyres. We are able to support consumer organisations that may be less experienced at product testing in the design of procedures for one-off tests. </p></li>
      <li><p><strong>Lab selection:</strong> We use around 50 highly specialised, independent laboratories worldwide to carry out our testing.</p> </li>
      <li><p><strong>Product purchase:</strong> We purchase the goods or services to be tested anonymously in-store or online, just as consumers do. This to ensure that we remain independent and impartial and that our results reflect the best information available to consumers.</p></li>
      <li><p><strong>Test results:</strong> We collect and share test data and results that are used by member organisations for publications on their associated magazines and websites. </p></li>
      <li><p><strong>Standardised reporting:</strong> We empower consumer organisations worldwide to standardise reports on the same product, hence improving the quality of information available to consumers.</p> </li>
      <li><p><strong>Evaluation:</strong> Member organisations are able to evaluate and interpret the test data provided by ICRT in accordance with their local priorities.</p></li>
    </ul>

<buttons-area :buttons="buttons" />
</template>

<script>
import ButtonsArea from "../../../../components/ButtonsArea/_ButtonsArea";

export default {
  name:'Testing',
  data() {
    return {
      buttons: [
        {
          path: "/work.html",
          name: "What we do",
          class: "left",
        },
         {
          path: "/work.html?section=survey_and_services",
          name: "Survey & services",
          class: "right",
        },
      ],
      width: window.innerWidth,
      isHorisontal:null,
    };
  },
   watch: {
    $width(width){
    },
    $isHorisontal(isHorisontal){}
  },
  created(){
        if (this.width>680) {
              this.isHorisontal=true;
            } else {
              this.isHorisontal=false;
            }
  },
  mounted() {
     window.addEventListener('resize', this.onResize);
    
  },
    methods: {  
        onResize() {
            this.width = window.innerWidth;
           if (this.width>680) {
              this.isHorisontal=true;
            } else {
              this.isHorisontal=false;
            }
        }
    },
 components: {
    ButtonsArea,
  },
};
</script>

<style lang="scss" scoped>
  @import './testing.scss';
</style>
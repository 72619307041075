<template lang="en">
  <div :class="className">
    
  </div>
</template>

<script>
export default {
  name: "BackgroundElements",
  props: {
    className: {
      type:String,
    }
  }
}
</script>

<style lang="scss">
  @import "./backgroundelements.scss";
</style>
<template lang="en">
<transition :name="direction" >
 <div v-show="visibleSlide === index" class="slide" :testemonial="testemonial">
  <slot></slot>
   
    <p class="text">"{{testemonial.text}}"</p>
    <div class="bottom-section">
       <div class="img_wrapper">
          <img :src="require(`../../../../../assets/images/membersLogos/${(testemonial.logo)}`)" alt="logo">
        </div>
        <div class="writtenBy">
          <p>{{testemonial.writtenBy.name}}</p>
          <p>{{testemonial.writtenBy.jobTitle}}</p>
        </div>
  </div>
</div>
</transition>
 
</template>

<script>
export default {
  name:"Slide",
  props:['visibleSlide', 'index', 'testemonial', "direction"],
}
</script>

<style scoped lang="scss">
  @import "./slide.scss";
</style>
<template lang="en">
    <h2>Special Research Projects</h2>
    <p>We selectively participate in research projects that are independently-funded, typically by the EU or national governments, which align with our priorities and that have the potential to do one or both of the following:</p>
    <ol class="pages-ol">
    <li><span>1</span><p>Strengthen consumer information through the development of innovative test methods or original testing approaches.</p></li>
    <li><span>2</span><p>Expand the amount of consumer testing we can conduct to the benefit of our members and consumers.</p></li>
    </ol>
    <p>Before engaging in a new project, ICRT follows a thorough due-diligence process to check the suitability of funders and to ensure that our impartiality is not put at risk. We also occasionally provide advisory support to selected projects which have the potential to produce beneficial results for our members and consumers.</p>
    <h3>Research Projects</h3>
    <ul>
      <li v-for="project in projects">
          <div class="heading">
              <span>
              <img :src="require(`../../../../assets/images/${project.logo}`)" :alt="project.name">
              </span>
            <div class="info">
                <h4> {{project.name}} </h4>
                <p>{{project.fullName}}</p>
                <small>{{project.dates}}</small>
                <small>{{project.grant_id}}</small>
            </div>
          </div>
          <div class="text">
                <p v-for="description in project.description">{{description }}</p>
                <a v-show="project.webLink!=='#'" target="_blank" :href="project.webLink"><p>Go to {{project.name}} website</p><span></span></a>
          </div>
        </li>
    </ul>
<ButtonsArea :buttons="buttons" />
</template>

<script>
import ButtonsArea from "../../../../components/ButtonsArea/_ButtonsArea";
import projectsData from "./projects.json";

export default {
  name:'ResearchProjects',
  components: {
    ButtonsArea,
  },
  data() {
    return {
      projects:projectsData,
      buttons: [
         {
          path: "/work.html?section=survey_and_services",
          name: "Survey & Services",
          class: "left",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import './researchProjects.scss';
</style>
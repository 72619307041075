<template lang="en">
  <section class="globalimpact">
    <div class="section__conatiner">
      <div class="map_wrapper">
      </div>
      <div class="text_wrapper">
        <h2>Global Impact</h2>
        <p>ICRT is the only independent international organisation for joint consumer research and testing. We have 30+ members that represent the interests of consumers in 34 countries worldwide, with the potential to reach more than 25m consumers through the publications of our member organisations.</p>
        <p>Our members include consumer organisations in Asia, Australia/Oceania, Europe, North America, and South America. Members range in size from the largest consumer organisations worldwide, with subscription or membership levels above five million, to smaller organisations with less than 10,000 subscribers/members.</p>
      <link-anchor-button :path="button.path" :name="button.name" :class="button.class" />
      </div>
    </div>
  </section>
</template>

<script>
import LinkAnchorButton from "../../../../components/LinkAnchorButton/_LinkAnchorButton.vue";
export default {
  name:"GlobalImpact",
  components: {
    LinkAnchorButton,
  },
  data() {
    return {
      button:{
          path:"/members.html",
          name:"Our members",
          class:"normal"
      }
    }
  }
}
 
</script>

<style scoped lang="scss">
  @import "./globalimapcthome.scss"
</style>
<template lang="en">
<!-- Page Sub-title -->
    <h2>Join ICRT</h2>
    <h3>How to become a member</h3>
    <p>To become a member of ICRT, a consumer organisation must be able to comply with our Articles of Association, have a consumer mission and be able to prove financial and editorial independence. They must also have the capacity to carry out research and testing projects and disseminate the results in print and digital media. The requirements and application process is described below.</p>
    <h3>Requirements</h3>
    <!-- Unordered list with Ticks -->
    <ul class="ticks-ul">
       <li>
          <div class="icon tick"></div>
          <div class="text">
              <h4>Consumer Mission</h4>
              <p>Act exclusively in the consumer interest</p>
          </div>
     </li>
       <li>
          <div class="icon tick"></div>
          <div class="text">
              <h4>Independence</h4>
              <p>Of commerce, industry and political parties</p>
          </div>
     </li>
       <li>
          <div class="icon tick"></div>
          <div class="text">
              <h4>No Ads</h4>
              <p>Doesn’t take money for advertising manufacturers’ products
</p>
          </div>
     </li>
      <li>
          <div class="icon tick"></div>
          <div class="text">
              <h4>Financially sustainable</h4>
              <p>Viable plan for long-term financial independence</p>
          </div>
     </li>
    </ul>
    <!-- Sub sub-heading + Ordered List with Steps -->
    <h3>Process for becoming a member</h3>
    <ol>
      <li v-for="step in steps" :key="step.number">
       <div class="description__container">
         <span class="number">{{step.number}}</span>
          <div class="description__text">
          <h4>{{step.heading}}</h4>
          <p>
            <strong>{{step.subHeading}}</strong>
            {{step.text}}
          </p>
           <div v-show="step.number=== 2"><a href="/docs/application.pdf" download="ICRT Membership Application form V1.1 2021-09-21.pdf"><span class="icon download"></span><p>Download Application (.pdf)</p></a></div>
          </div>
       </div>
          <img :src="require( `../../../../assets/images/${step.icon}`)" :alt="step.heading">
      </li>
    </ol>
<buttons-area :buttons="buttons" />
</template>

<script>
import ButtonsArea from "../../../../components/ButtonsArea/_ButtonsArea";
import stepsData from "./steps.json";

export default {
  name:'BecomeMember',
  components: {
    ButtonsArea,
  },
  data() {
    return {
      steps:stepsData,
      buttons: [
         {
          path: "/members.html?section=icrt_non_shareholders",
          name: "Non Shareholders",
          class: "left",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import './becomeMember.scss';
</style>
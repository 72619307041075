<template lang="en">
    <h2>Financial Information</h2>
    <p>International Consumer Research and Testing Ltd is a Private Limited Company registered in the UK - Company number 02447570. The company operates on a not for profit basis and is wholly owned by our members who are shareholders.</p>
    <p>ICRT is funded through an annual membership fee from members. ICRT refuses all corporate donations, including samples and advertising.</p>
    <p>Copy of the <a href="https://find-and-update.company-information.service.gov.uk/company/02447570/filing-history" target= '_blank'>Annual accounts for ICRT</a></p>
<ButtonsArea :buttons="buttons" />
</template>
<script>
import ButtonsArea from "../../../../components/ButtonsArea/_ButtonsArea";

export default {
  name: "FinancialInformation",
  components: {
    ButtonsArea,
  },
  data() {
    return {
      buttons: [
        {
          path: "/about.html?section=partners",
          name: "Partners",
          class: "right",
        },
         {
          path: "/about.html?section=ceo_and_board",
          name: "CEO & Board",
          class: "left",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "./financialinformation.scss";
</style>
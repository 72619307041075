<template lang="en">
    <h2>Our Partners</h2>
    <p>ICRT works closely with other international campaigning consumer organisations that play a crucial role in coordinating the efforts and impact of national consumer organisations. </p>
   <ul>
     <li v-for="partner in partners">
       <div class="text">
           <h3>{{partner.name}}</h3>
           <p>{{partner.description }}</p>
           <a target="_blank" :href="partner.webLink"><p>Go to {{partner.name}} website</p> <span></span></a>
       </div>
       <img :src="require(`../../../../assets/images/${partner.logo}`)" :alt="partner.name">
     </li>
   </ul>
<ButtonsArea :buttons="buttons" />
</template>

<script>
import ButtonsArea from "../../../../components/ButtonsArea/_ButtonsArea";
import parnersData from "./partners.json";

export default {
  name:'Partners',
     components: {
     ButtonsArea,
  },
  data() {
    return {
      partners:parnersData,
      buttons: [
        {
          path: "/about.html?section=financial_information",
          name: "Financial Information",
          class: "left",
        }
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import './partners.scss';
</style>
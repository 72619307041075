<template lang="en">
  <section id="whoweare" class="whoweare">
    <div class="section__conatiner">
      <div class="graphic_wrapper" :class="isHorisontal ? 'horisontal' : 'vertical'">
        <img v-show="isHorisontal === true" class="horisontal" src="../../../../assets/images/infographicWhoWeAreHorisontalHome.webp" alt="Infographic">
        <img v-show="isHorisontal===false" class="vertical" src="../../../../assets/images/infographicWhoWeAreVerticalHome.webp" alt="Infographic">
      </div>
      <div class="text_wrapper">
        <h2>Who we are</h2>
        <p></p>
        <p>ICRT is a global consortium of consumer organisations that work together on research and testing of consumer products. Founded in 1990, we are a not-for-profit organisation fully owned by our members. As such we are independent of product manufacturers, and act exclusively in the interest of the consumer.</p>
        <p>ICRT operates as two independent entities, ICRT Ltd and ICRT GmbH.</p>
        <ul class="pages-ul">
          <li><p>ICRT GmbH is an independent subsidiary based in Germany and is 100% owned by ICRT Ltd.</p> </li>
          <li><p>ICRT Ltd is based in the UK and 100% owned by our members, who are independent consumer organisations.</p></li>
        </ul>
        <p>ICRT is the only organisation of its kind. We enable consumer organisations worldwide to co-operate in providing high-quality and independent information on products and services, thus helping consumers make smart choices in increasingly complex markets.</p>
        <link-anchor-button :path="button.path" :name="button.name" :class="button.class" />
      </div>
    </div>
  </section>
</template>

<script>
import LinkAnchorButton from "../../../../components/LinkAnchorButton/_LinkAnchorButton.vue"

export default {
  name: "WhoWeAreHome",
  components:{
    LinkAnchorButton,
  },
  data() {
    return {
      button:{
          path:"/about.html",
          name:"Learn more about us",
          class:"normal"
      },
      width: window.innerWidth,
      isHorisontal:null,
    }
  },
  watch: {
    $width(width){
    },
    $isHorisontal(isHorisontal){}
  },
  created(){
        if (this.width>600 && this.width>=799) {
              this.isHorisontal=true;
            } else if(this.width>=800 && this.width<950){
              this.isHorisontal=false;
            } else if(this.width>950){
              this.isHorisontal=true;
            } 
            else {
              this.isHorisontal=false;
            }
  },
  mounted() {
     window.addEventListener('resize', this.onResize);
    
  },
  methods: {  
        onResize() {
            this.width = window.innerWidth;
            if (this.width>600 && this.width<800) {
              this.isHorisontal=true;
            } else if(this.width>800 && this.width<950){
              this.isHorisontal=false;
            } else if(this.width>950){
              this.isHorisontal=true;
            } 
            else {
              this.isHorisontal=false;
            }
        }
    }

};
</script>
<style scoped lang="scss">
@import "./whowearehome.scss";
</style>
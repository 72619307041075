<template lang="en">
  <h1 class='container'>
     {{heading}}
 </h1>
</template>
<script>

export default {
  name:'PageHeading',
    props: {
  	heading: {
      type: String,
  	}},
}
</script>

<style scoped lang="scss">
  @import './pageHeading.scss';
</style>
<template lang="en">
  <div v-show="showCookies === true" class="cookies">
        <div class='title_container'>
           <p class='white_text'>Privacy Notice</p>
        </div>
         <div class="cookie__container">
             <p>This website uses Analytics Cookie to monitor and analyse traffic. It will collect usage statistics such as the number of page visits, documents downloaded etc. and provides us with aggregate numbers. Any IP addresses collected have the last octet removed. This cookie is very important for us to evaluate the correct functioning of our website and the impact of our activities.</p>
             <div class="buttons">
              <button @click="clickAllow" class="allow">Allow </button>
              <button @click="clickDontAllow" class="dontAllow"> Don't Allow </button>
             </div>
          </div>
  </div>
</template>


<script>
export default {
  name:"CookiesMesage",
  data() {
    return {
      showCookies: true,
    } 
  },
  mounted(){
    let cookie = localStorage.getItem("isAllowed");
    if (cookie !== null) {
      this.showCookies= false;
    }
  },
  methods: {
    clickAllow(){
      localStorage.setItem('isAllowed', 'true');
      this.showCookies=false;
      window.location.reload(true); 
    },
    clickDontAllow(){
      localStorage.setItem('isAllowed', 'false');
      this.showCookies=false;
    }
  }
}
</script>


<style lang="scss">
    @import './cookiesMessage.scss';
</style>
<template lang="en">
  <h2>Our impact</h2>
  <p>The collaborative research and testing of consumer products and services undertaken by ICRT and its members generates many positive outcomes for wider society, including manufacturers, legislators and regulators as well as consumers. </p>
   <ul class="ticks-ul">
     <li>
       <div class="icon tick"></div>
       <div class="text">
          <h3>Consumers</h3>
          <p>Our testing identifies the best products and helps consumers make the right choice for themselves and the environment</p>
       </div>
    </li>
    <li>
        <div class="icon tick"></div>
        <div class="text">
          <h3>Manufacturers</h3>
          <p>Our testing encourages manufacturers to improve the quality of products</p>
        </div>
      </li>
      <li>
        <div class="icon tick"></div>
        <div class="text">
            <h3>Legislators and Regulators</h3>
            <p> Our research informs authorities about how to raise the standards of products and services through legislation and regulation</p>
        </div>
    </li>  
   </ul>
   <h2>Euro NCAP: raising car safety standards</h2>
   <p>ICRT was a founding member of the <a href="https://www.euroncap.com/en">Euro NCAP (European New Car Assessment Programme)</a> crash test programme in 1996.  We have been influential in improving car safety from the early days of Euro NCAP.</p>
   <p>More recently, we have been closely involved in setting up <a href="https://www.greenncap.com/">Green NCAP</a> , which focuses on cars’ environmental impact, and <a href="https://www.latinncap.com/en/">Latin NCAP</a>, plus the launch of the <a href="https://www.globalncap.org/">Global NCAP</a> programme. The CEO of ICRT, Niels Ebbe Jacobsen, is also chairman and President of Euro NCAP.</p>
<ButtonsArea :buttons="buttons" />
</template>

<script>
import ButtonsArea from "../../../../components/ButtonsArea/_ButtonsArea";

export default {
  name:'SuccessStories',
  components: {
    ButtonsArea,
  },
  data() {
    return {
      buttons: [
        {
          path: "/about.html?section=mission_and_vision",
          name: "Mission & Vision",
          class: "left",
        },
         {
          path: "/about.html?section=history",
          name: "History",
          class: "right",
        },
      ],
    };
  },

};

</script>
<style lang="scss" scoped>
@import './successStories.scss';
</style>
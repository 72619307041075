<template lang="en">
  <div class="accessibility__section" :class="{accessibility__section: true, active: isOpenA11y, homepage: homepage}">
    <div class="container">
      <a tabindex="1" @focus="openMethod" class="skip-to-content-link"  :href="hrefTo">
        Skip to content
      </a>
      <div class="text-control"> 
        <div aria-label="decrease text size" role="button" @click="decreaseText" class="letter"><span class="letterIcon small" :class="{active: activeLetter === 'small'}"></span></div>
        <div aria-label="normal text size"  role="button" @click="normalText" class="letter"><span class="letterIcon normal" :class="{active: activeLetter === 'normal'}"></span></div>
        <div aria-label="increase text size"  role="button" @click="increaseText" class="letter"><span class="letterIcon large" :class="{active: activeLetter === 'large'}"></span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:"Accessibility",
  data(){
    return {
      activeLetter: null,
    }
  },
  mounted() {
    let letter = localStorage.getItem("activeLetter");
    if(letter == null) {
     this.activeLetter = "normal"; 
    } else {
     this.activeLetter = letter; 
    }
  },
  props:{
      hrefTo: {
        type: String,
      },
      isOpenA11y: {
        type: Boolean
      },
      openMethod: { 
        type: Function 
        },
      homepage: {
          type: Boolean,
        },
    },
  methods: {
      decreaseText() {
          document.querySelector("#main").style.fontSize="80%";
          localStorage.setItem('fontSize', '80%');
          localStorage.setItem('activeLetter', 'small');
          this.activeLetter = "small";
            },
      normalText() {
          document.querySelector("#main").style.fontSize="100%";
          localStorage.removeItem("fontSize");
          localStorage.setItem('activeLetter', 'normal');
          this.activeLetter = "normal";

        },
      increaseText() {
          document.querySelector("#main").style.fontSize="130%";
          localStorage.setItem('fontSize', '130%');
          localStorage.setItem('activeLetter', 'large');
          this.activeLetter = "large";

        },
  }
}
</script>

<style scoped lang="scss">
@import "./accessibility.scss";
</style>
<template lang="en">
  <header :class="{active: isActive, home: homePage}"  >
    <navigation :hrefTo="hrefTo" :classHome="classHome"/>
    <mobile-navigation :homePage="homePage"/>
  </header>
</template>


<script>
import Navigation from "../Navigation/_Navigation.vue";
import MobileNavigation from "../MobileNavigation/_MobileNavigation.vue";

export default {
  name:"Header",
  components:{
    Navigation,
    MobileNavigation,
  },
  props:["hrefTo", "isActive", "classHome", "homePage"],
}
</script>

<style lang="scss" scoped>
  @import "./header.scss";
</style>